import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TeamCard from "./team-card";

const TeamOne = ({ extraClassName='', specialists=[], specialistsIntro={article:'', noun:''} }) => {
  if (specialists?.length === 0) return null
  return (
    <section
      className={`team_member_area section_padding section_bottom_padding text-center ${extraClassName}`}
    >
      <Container>
        <Row className="d-flex justify-content-between">
          <Col lg={12}>
            <div className="hero-section-title text-center">
              <h1>{`${specialistsIntro.article} ${specialistsIntro.noun}`}</h1>
            </div>
          </Col>
          {specialists.map((data, index) => (
            <Col lg={3} md={6} sm={12} key={index}>
              <TeamCard data={data} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default TeamOne;
