import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogCard from "@/components/blog-card";
import { BlogOneData } from "@/data";
import { graphql, useStaticQuery } from "gatsby"


const BlogOne = () => {
  const data = useStaticQuery(graphql`
    query {
      pharmacy {
        newsBannerImg
        env {
          APISERVER
          TENANTID
        }
      }
    }
  `)

  const { env } = data.pharmacy

  const [news, setNews] = useState([])

  function adaptNews(newsData) {
    let data = []
    newsData.map((news, idx) =>
      data.push({
        pk: news.id,
        img: news.image,
        badge: news.image,
        title: news.title,
        subtitle: news.headline,
        slug: news.slug,
        author: "",
        body: news.content,
        linksTo: "/about",
        detailPage: `/novedades/${news.id}`,
        date: news.date_published,
        tags: news.tags.map(tag => tag.name),
        categorie: news.category.name,
      })
    )
    return data
  }  

  useEffect(() => {
    fetch(`${env.APISERVER}/core/noticias/get_data/?tenant=${env.TENANTID}`)
      .then(response => response.json())
      .then(resultData => {
        setNews(adaptNews(resultData["news"]))
      })
      .catch(err => console.log(err))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const { title } = BlogOneData;
  const posts = news?.map((newsItem, idx) => {
    const date = new Date(newsItem.date)
    return {
      image: newsItem.img,
      title: newsItem.title,
      text: newsItem.subtitle,
      date: `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`,
      url: newsItem.detailPage,

      }}
  )

  if (!news || !news.length) return null
  
  return (
    <section className="blog_share_area section_padding">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="hero-section-title text-center">
              <h1>{title}</h1>
            </div>
          </Col>
          {posts?.slice(0,3).map((data, index) => (
            <Col lg={4} md={6} sm={12} key={`blog-one-${index}`}>
              <BlogCard data={data} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default BlogOne;
