import React from "react";
import Img from "./img";
import Link from "@/components/link";

const BlogCard = ({ data }) => {
  const { image, date, title, url, text } = data;
  return (
    <div className="d-flex flex-column blog_share_box">
      <div className="bl_share_img">
        <Link href={url}>
          <Img
            src={image}
            alt={title}
            className="img-fluid"
            style={{
              height: "14rem",
              objectFit: "cover",
              margin: "auto",
            }} />
        </Link>

        <span className="blog_date">{date}</span>
      </div>
      <div className="blog_share_details">
        {/* <span className="comment_author">
          by <Link href={url}>{author}</Link> -{" "}
          <Link href={url}>{commentCount} Comments</Link>
        </span> */}
        <h1>
          <Link href={url}>{title}</Link>
        </h1>
        <p>{String(text).length < 100 ? text : String(text).slice(0, 100).slice(0, String(text).slice(0, 100).lastIndexOf(" "))+' (...)'}</p>
        
        
      </div>
      <div className="d-flex px-3 mt-auto justify-content-end w-100">
        <Link href={url}>LEER MÁS</Link>
        </div>
    </div>
  );
};

export default BlogCard;
