import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const AboutTwo = ({tenantName='', welcome}) => {

  return (
    <section className="about_service_area about_service_area_section_padding">
      <Container>
        <Row>
                    <Col lg={6}>
            <div className="about_service_right">
              <div className="hero-title-with-shape">
                <h4 className="heading_with_border">Bienvenido a {tenantName}</h4>
                <h1>{welcome?.pitch}</h1>
              </div>
              <h2 dangerouslySetInnerHTML={{ __html: welcome?.content}}/>
  
            </div>
          </Col>
          <Col lg={6}>
            <div className="about_service_left"
              style={{
                backgroundImage: `url(${welcome?.teamImg})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
            >
            </div>
          </Col>

        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
